import {
	DefaultMainMenu,
	DefaultMainMenuContent,
	TLComponents,
	Tldraw,
	TldrawUiMenuGroup,
	TldrawUiMenuItem,
} from 'tldraw'
import 'tldraw/tldraw.css'

function CustomMainMenu() {
	return (
		<DefaultMainMenu>
		</DefaultMainMenu>
	)
}

export default function Editor() {
  return (
    <div style={{ position: "fixed", inset: 0 }}>
      <Tldraw components={components} />
    </div>
  );
}

const components: TLComponents = {
	MainMenu: null,
    PageMenu: null,
    HelpMenu: null,
}
